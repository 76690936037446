:root {
    --primary-color: #4D8C7E;
    --primary-light-color: #729e7a;
    --secondary-color: #405664;
    --secondary-light-color: #59656d;
}

.welcome-carousel {
    height: 2150px;
}

.bg-pattern {
    background-image: url(/assets/img/pattern.svg) !important;
    background-size: cover !important;
} 
.open-keyboard {
    h5.title {
        padding-top: 215px;
    }
}
.overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 7;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.75);
    overflow-x: hidden;
    transition: 0.5s;

    &.success-modal {
        .modal-dialog {
            max-width: 90% !important;
        }
        .modal-content {
            padding: 220px 100px;
        }
        .exit-btn {
            position: absolute;
            right: 100px;
            top: 100px;
            background-color: transparent;
            border: none;
            img {
                width: 100px;
            }
        }
        .check-modal-text-box {
            img {
                height: auto;
                width: auto;
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
             }
            h1,h2,h3,h4,h5 {
                margin-bottom: 32px;
                margin-top: 0;
                line-height: 1.2;
            }
            h1 {
                font-size: 160px; 
                font-weight: 700;
            }
            h2 {
                font-size: 138px;
                font-weight: 600;
            }
            h3 {
                font-size: 106px;
                font-weight: 600;
            }
            h4 {
                font-size: 95px;
                font-weight: 500;
            }
            h5 {
                font-size: 90px;
                font-weight: 500;
            }
            p {
                font-size: 80px;
                margin-bottom: 32px; 
            }
            ul,ol {
                padding-left: 120px;
            }
            li {
                margin-bottom: 24px;
            }
            a {
                text-decoration: underline;
            }
        }
    }
}


.tenant-list-container {
    padding-right: 150px;
    position: relative;
    height: 1400px;
    
    .row {
        max-height: 100%;
        overflow-y: auto;
        scroll-snap-type: y mandatory;
        
        .col {
            scroll-snap-align: start;
            scroll-snap-stop: always;
            font-size: 42px;
        }
    }
    .row::-webkit-scrollbar {
        display: none;
    }
    
    
}
.search-btn {
    position: absolute;
    right: 125px;
    width: 100px;
    height: 100px;
    border: 6px solid #607786;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 300px;
    img {
    width: 55px;
    filter: brightness(0) saturate(100%) invert(46%) sepia(16%) saturate(538%) hue-rotate(160deg) brightness(95%) contrast(89%);
    }
}
.scroll-btn {
  position: absolute;
  right: -78px;
  width: 100px;
  height: 100px;
  cursor: pointer;
  background-color: transparent;
  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  filter: brightness(0) saturate(100%) invert(49%) sepia(9%) saturate(1005%) hue-rotate(161deg) brightness(89%) contrast(83%);
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  border: 6px solid #607786;
}

.top-scroll {
  top: 0px;
  background-image: url('/assets/kowerk/icons/arrow-up.svg');
}
.bottom-scroll {
  top: auto;
  bottom: 160px;
  background-image: url('/assets/kowerk/icons/arrow-down.svg');
}
.calculator-buttons {
  .btn-outline-secondary:hover {
    color: var(--secondary-color);
    border-color: var(--secondary-color);
    background: #fff;
  }
  .btn-outline-secondary:active {
    border-color: var(--secondary-light-color);
    color: #FFFFFF;
    background: var(--secondary-light-color);
  }
}
.code-block {
    h1 {
        z-index: 5;
        position: relative;
        margin-right: 150px;
    }
    .close {
        right: 125px;
    }
}
.numpad-btn {
    img {
      width: 180px;
    }
    button {
      border: none;
      background-color: transparent;
    }
    .numpad-text {
        font-size: 70px;
    }
    .numpad-text-open {
        display: none;
    }
    .numpad-text-active .numpad-text-hidden{
        display: none;
    }
    .numpad-text-active .numpad-text-open{
        display: block;
        color: #198754;
    }
}
.call-tenant-container {
    min-height: 2529px;
    .modal-button {
        padding-bottom: 50px;
    }
    .overlay {
        &.touchpad-modal-open {
            .logo-container {
                display: none !important;
            }
            .modal-button,
            .call-status,
            .tenant-name {
                display: none;
            }
            .numpad-btn img {
                filter: brightness(0) saturate(100%) invert(42%) sepia(13%) saturate(2297%) hue-rotate(100deg) brightness(95%) contrast(88%);
            }
        }
    }
    &.open-keyboard {
        min-height: 2000px;
        padding-top: 70px;
        padding-bottom: 70px;
    }
}
.padlock {
    &.verified {
        top: 180px;
        width: 350px;
        right: 250px;
        z-index: 5;
        img {
            width: 250px;
        }
    }
}